import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'

const themeDefault = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

const NotFoundPage = () => (
    <ThemeProvider value={themeDefault}>
        <Layout>
            <SEO title="404: Not found" />
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Layout>
    </ThemeProvider>
)

export default NotFoundPage
